import { api, handleResponse, handleError } from "./apiServices";

export const getartwork = token =>
  api(token)
    .get("/artworks/list")
    .then(handleResponse)
    .catch(handleError);

export const updateartwork = (token, data) =>
  api(token)
    .put(`/artwork/update`, data)
    .then(handleResponse)
    .catch(handleError);
