import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
        aboutus: Yup.string().required("Please enter aboutus"),
    }),
    validateOnMount: true,
    mapPropsToValues: (props) => ({
        aboutus: "<p></p>",
        // parent_id: 0,
    }),
    handleSubmit: (values) => {},
    displayName: "CustomValidationForm",
    enableReinitialize: true,
});

export default formikEnhancer;
