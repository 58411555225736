import { api, handleResponse, handleError } from "./apiServices";

export const getFAQs = token =>
  api(token)
    .get("/faqs/list")
    .then(handleResponse)
    .catch(handleError);

export const getFAQTypes = token =>
  api(token)
    .get("/faqs/types")
    .then(handleResponse)
    .catch(handleError);

export const addFAQ = (token, data) =>
  api(token)
    .post("/faqs/add", data)
    .then(handleResponse)
    .catch(handleError);

export const updateFAQ = (token, data) =>
  api(token)
    .put("/faqs/update", data)
    .then(handleResponse)
    .catch(handleError);

export const deleteFAQ = (token, data) =>
  api(token)
    .delete("/faqs/delete", { data: data })
    .then(handleResponse)
    .catch(handleError);
