import React, { useCallback, useEffect, useMemo, useState } from "react";
// import enhancer from "enhancer/IPAddenhancer"
import NavigationActions from "redux/navigation/actions";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import AuthActions from "redux/auth/actions";
import { Modal } from "reactstrap";
import ReactTableWrapper from "components/reacttable/reacttbl.style";
import classNames from "classnames";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import Pagination from "components/common/Pagination";
import { deleteIPAdd, getIPAdd } from "services/IPAddServices";
import ConformationModal from "components/common/ConformationModal";
import { Filter, Trash } from "react-feather"; //Edit3, Plus,
import moment from "moment";
import "../../../assets/css/tracking_page.css";
import IPFilterModal from "./IPFilterModal";
const { success, error, fetching } = NavigationActions;
const { setuser } = AuthActions;

const IPData = props => {
  const { success, error, fetching } = props;
  const [refresh, toggleRefresh] = useState(true);
  const [IPAddsList, setIPAddList] = useState([]);
  const [isOpen, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const [openDeleteModal, toggleDeleteModalOpen] = useState();
  const [deleteId, setDeleteID] = useState("");

  const HeaderComponent = props => {
    let classes = {
      "-sort-asc": props.isSortedDesc !== undefined && !props.isSortedDesc,
      "-sort-desc": props.isSortedDesc !== undefined && props.isSortedDesc
    };
    return <div className={classNames(classes)}>{props.title}</div>;
  };

  const getIPAddList = useCallback(async data => {
    fetching();

    await getIPAdd(data).then(data => {
      if (data?.success) {
        setIPAddList(data.data);
        success();
        toggleRefresh(false);
      } else {
        error(data?.message);
      }
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    refresh && getIPAddList();
    // eslint-disable-next-line
  }, [refresh]);

  useEffect(() => {
    console.log(editData);
    getIPAddList(editData);
    // eslint-disable-next-line
  }, [editData]);

  const columns = useMemo(
    () => [
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="IP Address"
            />
          );
        },
        // Filter: FilterComponent,
        placeholder: "IP Address",
        disableFilters: true,
        accessor: "IP_Address",
        Cell: tableInstance => (
          <span className="text-capitalize">
            {tableInstance.row.values.IP_Address}
          </span>
        )
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Category Name"
            />
          );
        },
        placeholder: "Category Name",
        disableFilters: true,
        accessor: "category_name",
        Cell: tableInstance => (
          <span className="text-capitalize">{tableInstance.value}</span>
        )
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Product Name"
            />
          );
        },
        // Filter: FilterComponent,
        placeholder: "Product Name",
        disableFilters: true,
        accessor: "product_name",
        Cell: tableInstance => (
          <span className="text-capitalize">
            {tableInstance.row.values.product_name}
          </span>
        )
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Passed Step"
            />
          );
        },
        placeholder: "Passed Step",
        disableFilters: true,
        accessor: "stepName",
        Cell: tableInstance => (
          <span className="text-capitalize">{tableInstance.value}</span>
        )
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Visited At"
            />
          );
        },
        placeholder: "Visited At",
        disableFilters: true,
        accessor: "created_at",
        Cell: tableInstance => (
          <span className="text-capitalize">
            {moment(tableInstance.value).format("DD-MM-YY")}
          </span>
        )
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Action"
            />
          );
        },
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        Cell: tableInstance => {
          return (
            <div className="react-action-class">
              {/* <button
                className="table-action action-edit"
                style={{
                  cursor:
                    tableInstance.row.original.id > 2 ? "pointer" : "default"
                }}
                disabled={tableInstance.row.original.id > 2 ? false : true}
                onClick={() => {
                  if (
                    tableInstance.row.original.id !== 2 &&
                    tableInstance.row.original.id !== 1
                  ) {
                    props.history.push(
                      `/IPAdd/Edit/${tableInstance.row.original.id}`
                    );
                  }
                  // setEditData(tableInstance.row.original);
                  // setIsEdit(true);
                  // setOpenModal(true);
                }}
              >
                <Edit3 className="table-icon-edit" />
              </button> */}
              <button
                className="table-action action-delete"
                onClick={() => {
                  toggleDeleteModalOpen(true);
                  setDeleteID(tableInstance.row.original.id);
                }}
              >
                <Trash className="table-icon-edit" />
              </button>
            </div>
          );
        }
      }
    ],
    // eslint-disable-next-line
    [getIPAddList]
  );
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    headerGroups,
    pageCount,
    gotoPage,
    state: { pageIndex }
  } = useTable(
    {
      data: IPAddsList,
      columns: columns,
      initialState: {
        pageSize: 10,
        pageIndex: 0
      }
    },
    useFilters,
    useSortBy,
    usePagination
  );
  const deleteClick = () => {
    deleteIPAdd({ id: deleteId }).then(res => {
      if (res.success) {
        toggleRefresh(true);
        toggleDeleteModalOpen(false);
        success(res.message);
      } else {
        error(res.message);
      }
    });
  };
  return (
    <div className="card">
      <div className="container-fluid">
        <div className="row title-sec align-items-center">
          <div className="col-sm-6 col-6 headline mb-15 mt-15">
            Tracking Data
          </div>
          <div className="col-sm-6 col-6 headline mb-15 mt-15">
            <div className="Track_filter">
              <button
                className="filter_btn add_product_design"
                onClick={() => {
                  // setEditData(tableInstance.row.original);
                  // setIsEdit(true);
                  setOpenModal(true);
                }}
              >
                <Filter className="mr-2" />
                Filter
              </button>
            </div>
          </div>

          <div className="col-sm-auto ml-auto">
            {/* <button
              className="btn btn-blue"
              onClick={() => props.history.push("/IPAdd/Add")}
            >
              <Plus className="mr-2" /> Add IPAdd
            </button> */}
          </div>
        </div>
        <div className="div-container">
          <ReactTableWrapper {...props}>
            <div className="table-responsive common-table">
              <table className="table border-0" {...getTableProps()}>
                <thead className="thead-dark">
                  {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(header => (
                        <th
                          {...header.getHeaderProps(
                            header.getSortByToggleProps()
                          )}
                        >
                          <div>{header.render("Header")}</div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {/* {headerGroups.map((headerGroup) => (
                                  <tr {...headerGroup.getHeaderGroupProps()}>
                                      {headerGroup.headers.map((header) => {
                                          return (
                                              <td
                                                  {...header.getHeaderProps(
                                                      header.getSortByToggleProps()
                                                  )}
                                              >
                                                  <div>
                                                      {header.canFilter
                                                          ? header.render(
                                                                "Filter"
                                                            )
                                                          : null}
                                                  </div>
                                              </td>
                                          );
                                      })}
                                  </tr>
                              ))} */}
                  {page.map(row => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map(cell => (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <Pagination
              onPageChange={gotoPage}
              pages={pageCount}
              page={pageIndex}
            />
          </ReactTableWrapper>
        </div>
        <Modal isOpen={isOpen} backdrop={true}>
          {isOpen && (
            <IPFilterModal
              onClose={() => {
                setOpenModal(false);
                setIsEdit(false);
                // setEditData({});
              }}
              isEdit={isEdit}
              editData={editData}
              setEditData={setEditData}
              toggleRefresh={e => toggleRefresh(e)}
            />
          )}
        </Modal>
        <Modal isOpen={openDeleteModal} backdrop={true}>
          {openDeleteModal && (
            <ConformationModal
              isOpen={openDeleteModal}
              onClose={() => toggleDeleteModalOpen(false)}
              confirmText={"Delete"}
              message={"Are you sure you want to delete IPAdd ?"}
              handleConfirm={() => deleteClick()}
            />
          )}
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken,
    user: state.auth.user
  };
};
export default compose(
  withRouter,
  // enhancer,
  connect(mapStateToProps, { success, error, fetching, setuser })
)(IPData);
