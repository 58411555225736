import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
        faq_id: Yup.string().required("Please Enter FAQ Type"),
        faq_name: Yup.string().matches(
            /^[A-Za-z /]*$/,
            "Please Enter Valid FAQ Type"
        ),
        question: Yup.string().required("Please Enter Question"),
        answer: Yup.string().required("Please Enter Answer"),
    }),
    validateOnMount: true,
    mapPropsToValues: (props) => ({
        question: "",
        answer: "",
        faq_id: "",
        faq_name: "",
    }),
    handleSubmit: (values) => {},
    displayName: "CustomValidationForm",
    enableReinitialize: true,
});

export default formikEnhancer;
