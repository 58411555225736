import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    product_id: Yup.string(),
    category_id: Yup.string(),
    // startDate: Yup.string().when("endDate", {
    //   is: value => (value),
    //   then: Yup.string().required("Please select start date"),
    //   otherwise: Yup.string()
    //   }),
    endDate: Yup.string().when("startDate", {
      is: value => value,
      then: Yup.string().required("Please select end date"),
      otherwise: Yup.string()
    })
  }),
  validateOnMount: true,
  mapPropsToValues: props => ({
    product_id: "",
    category_id: "",
    startDate: "",
    endDate: ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
