import React, { useCallback, useEffect, useMemo, useState } from "react";
import NavigationActions from "redux/navigation/actions";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import AuthActions from "redux/auth/actions";
import { Modal } from "reactstrap";
import ReactTableWrapper from "components/reacttable/reacttbl.style";
import classNames from "classnames";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import Pagination from "components/common/Pagination";
import { deleteProduct, getProductsTable } from "services/productServices";
import ConformationModal from "components/common/ConformationModal";
import { Edit3, Plus, Trash } from "react-feather";
import ImportProductsmodal from "./importProductsmodal";
import "../../../assets/css/products_css.css";
// import "../../../assets/css/dash.css";
import "../../../assets/css/thumbnail.css";
import ReactSelect from "react-select";
import { getCategories } from "services/categoryServices";

//grouping

// import Select, { components, GroupProps } from 'react-select';
// import {
//   ColourOption,
//   colourOptions,
//   FlavourOption,
//   groupedOptions,
// } from '../data';

const { success, error, fetching } = NavigationActions;
const { setuser } = AuthActions;

const Products = props => {
  const { token, success, error, fetching } = props;
  // const [isOpen, setOpenModal] = useState();
  // const [isEdit, setIsEdit] = useState(false);
  // const [editData, setEditData] = useState({});
  const [refresh, toggleRefresh] = useState(true);
  const [productsList, setProductsList] = useState([]);
  const [openDeleteModal, toggleDeleteModalOpen] = useState();
  const [deleteId, setDeleteID] = useState("");
  const [isOpen, setOpenModal] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const [search, setSearch] = useState({ search: "", searchCat: "" });
  const [FMcatOpt, setFMCatOpt] = useState([]);
  const [PcatOpt, setPCatOpt] = useState([]);

  const HeaderComponent = props => {
    let classes = {
      "-sort-asc": props.isSortedDesc !== undefined && !props.isSortedDesc,
      "-sort-desc": props.isSortedDesc !== undefined && props.isSortedDesc
    };
    return <div className={classNames(classes)}>{props.title}</div>;
  };

  const categorylist = async () => {
    await getCategories().then(data => {
      if (data?.success) {
        const FMOpt = data.data
          .filter(val => val.parent_id == 1)
          .map(val => ({ label: val.name, value: val.id }));
        const POpt = data.data
          .filter(val => val.parent_id == 2)
          .map(val => ({ label: val.name, value: val.id }));

        setFMCatOpt(FMOpt);
        setPCatOpt(POpt);

        success();
        toggleRefresh(false);
      } else {
        error(data?.message);
      }
    });
  };

  const Group = [
    { label: "Fridge Magnets", options: FMcatOpt },
    { label: "Printing Products", options: PcatOpt }
  ];

  const getProductsList = useCallback(async () => {
    fetching();

    await getProductsTable(token, search).then(data => {
      if (data?.success) {
        setProductsList(data.data);
        success();
        toggleRefresh(false);
      } else {
        error(data?.message);
      }
    });
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    refresh && getProductsList();
    // eslint-disable-next-line
  }, [refresh]);

  useEffect(() => {
    categorylist();

    //eslint0disable-next-line
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Product Image"
            />
          );
        },
        // Filter: FilterComponent,
        placeholder: "Product Image",
        disableFilters: true,
        accessor: "product_image",
        Cell: tableInstance => (
          <div className="thumbnail_img">
            {/* eslint-disable-next-line */}
            <img
              src={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${tableInstance.row.values.product_image}`}
              alt="Image Not Found"
            ></img>
          </div>
        )
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Product Name"
            />
          );
        },
        // Filter: FilterComponent,
        placeholder: "Product Image",
        disableFilters: true,
        accessor: "product_name",
        Cell: tableInstance => (
          <span className="text-capitalize">
            {tableInstance.row.values.product_name}
          </span>
        )
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Category Name"
            />
          );
        },
        // Filter: FilterComponent,
        placeholder: "Category Name",
        disableFilters: true,
        accessor: "category_id",
        Cell: tableInstance => (
          <div>
            <span className="text-capitalize">
              {tableInstance.row.original?.category_name &&
              tableInstance.row.original?.category_name !== null
                ? tableInstance.row.original?.category_name
                : tableInstance.row.original?.parent_category_name}
            </span>
          </div>
        )
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Status"
            />
          );
        },
        // Filter: FilterComponent,
        placeholder: "Status",
        disableFilters: true,
        accessor: "product_status",
        Cell: tableInstance => (
          <div>
            <span className="text-capitalize">
              {tableInstance.row.original.product_status}
            </span>
          </div>
        )
      },
      // {
      //   Header: tableInstance => {
      //     return (
      //       <HeaderComponent
      //         isSortedDesc={tableInstance.column.isSortedDesc}
      //         title="Size"
      //       />
      //     );
      //   },
      //   // Filter: FilterComponent,
      //   placeholder: "Size",
      //   disableFilters: true,
      //   accessor: "size",
      //   Cell: tableInstance => (
      //     <div>
      //       <span className="text-capitalize">
      //         {tableInstance.row.original.size}
      //       </span>
      //     </div>
      //   )
      // },
      // {
      //   Header: tableInstance => {
      //     return (
      //       <HeaderComponent
      //         isSortedDesc={tableInstance.column.isSortedDesc}
      //         title="Paper"
      //       />
      //     );
      //   },
      //   // Filter: FilterComponent,
      //   placeholder: "Paper",
      //   disableFilters: true,
      //   accessor: "paper",
      //   Cell: tableInstance => (
      //     <div>
      //       <span className="text-capitalize">
      //         {tableInstance.row.original.paper}
      //       </span>
      //     </div>
      //   )
      // },
      // {
      //   Header: tableInstance => {
      //     return (
      //       <HeaderComponent
      //         isSortedDesc={tableInstance.column.isSortedDesc}
      //         title="Marker"
      //       />
      //     );
      //   },
      //   // Filter: FilterComponent,
      //   placeholder: "Marker",
      //   disableFilters: true,
      //   accessor: "marker",
      //   Cell: tableInstance => (
      //     <div>
      //       <span className="text-capitalize">
      //         {tableInstance.row.original.marker}
      //       </span>
      //     </div>
      //   )
      // },

      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Action"
            />
          );
        },
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        Cell: tableInstance => {
          return (
            <div className="react-action-class">
              <button
                className="table-action action-edit"
                onClick={() => {
                  props.history.push(
                    `/products/Edit/${tableInstance.row.original.id}`
                  );
                }}
              >
                <Edit3 className="table-icon-edit" />
              </button>
              <button
                className="table-action action-delete"
                onClick={() => {
                  toggleDeleteModalOpen(true);
                  setDeleteID(tableInstance.row.original.id);
                }}
              >
                <Trash className="table-icon-edit" />
              </button>
            </div>
          );
        }
      }
    ],
    // eslint-disable-next-line
    [getProductsList]
  );
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    headerGroups,
    pageCount,
    gotoPage,
    state: { pageIndex }
  } = useTable(
    {
      data: productsList,
      columns: columns,
      initialState: {
        pageSize: 10,
        pageIndex: 0
      }
    },
    useFilters,
    useSortBy,
    usePagination
  );
  const deleteClick = () => {
    deleteProduct(token, { id: deleteId }).then(res => {
      if (res.success) {
        setProductsList(res.data);
        success();
        toggleRefresh(true);
        toggleDeleteModalOpen(false);
      } else {
        error(res.message);
      }
    });
  };

  return (
    <div className="card m-2 p-2">
      <div className="container-fluid">
        <div className="row title-sec align-items-center">
          <div className="col-sm headline">Products</div>
          <div className="col-sm-auto text-right">
            {/* <button
              className="btn btn-blue"
              onClick={() => {
                // setEditData(tableInstance.row.original);
                setIsEdit(true);
                setOpenModal(true);
              }}
            >
              <span>
                <Plus className="mr-2" /> Import{" "}
              </span>
            </button> */}
            <button
              className="btn btn-blue add_product_design"
              onClick={() => {
                props.history.push("/Products/Add");
              }}
            >
              <Plus className="mr-2" /> Add Product
            </button>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-6 pr-1 ">
                <input
                  type="text"
                  className="form-control react-form-input search_focus"
                  placeholder="Search By Product Name"
                  id="search"
                  // onBlur={}
                  onChange={e => {
                    setSearch({ ...search, search: e.target.value });
                    toggleRefresh(true);
                  }}
                  // value={values.marker}
                />
              </div>
              <div className="col-6 pl-1">
                <ReactSelect
                  className="w-75"
                  isClearable
                  placeholder="Search By Category"
                  options={Group}
                  onChange={val => {
                    setSearch({ ...search, searchCat: val ? val.value : "" });
                    toggleRefresh(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          {/* <div className="w-50 mx-auto">
          <ReactSelect
            options={Group}
            onChange={(val) => {
              setSearch({ ...search, searchCat: val.value });
              toggleRefresh(true);
            }}
          />
          </div> */}
        </div>

        <div className="div-container">
          <ReactTableWrapper {...props}>
            <div className="table-responsive common-table">
              <table className="table border-0" {...getTableProps()}>
                <thead className="thead-dark">
                  {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(header => (
                        <th
                          {...header.getHeaderProps(
                            header.getSortByToggleProps()
                          )}
                        >
                          <div>{header.render("Header")}</div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {/* {headerGroups.map((headerGroup) => (
                                  <tr {...headerGroup.getHeaderGroupProps()}>
                                      {headerGroup.headers.map((header) => {
                                          return (
                                              <td
                                                  {...header.getHeaderProps(
                                                      header.getSortByToggleProps()
                                                  )}
                                              >
                                                  <div>
                                                      {header.canFilter
                                                          ? header.render(
                                                                "Filter"
                                                            )
                                                          : null}
                                                  </div>
                                              </td>
                                          );
                                      })}
                                  </tr>
                              ))} */}
                  {page.map(row => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map(cell => (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <Pagination
              onPageChange={gotoPage}
              pages={pageCount}
              page={pageIndex}
            />
          </ReactTableWrapper>
        </div>
        <Modal style={{ maxWidth: "400px" }} isOpen={isOpen} backdrop={true}>
          {isOpen && (
            <ImportProductsmodal
              onClose={() => {
                setOpenModal(false);
                setIsEdit(false);
                setEditData({});
              }}
              // token={token}
              isEdit={isEdit}
              editData={editData}
              toggleRefresh={e => toggleRefresh(e)}
            />
          )}
        </Modal>

        <Modal isOpen={openDeleteModal} backdrop={true}>
          {openDeleteModal && (
            <ConformationModal
              isOpen={openDeleteModal}
              onClose={() => toggleDeleteModalOpen(false)}
              confirmText={"Delete"}
              message={"Are you sure you want you want to delete product ?"}
              handleConfirm={() => deleteClick()}
            />
          )}
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken,
    user: state.auth.user
  };
};
export default compose(
  withRouter,
  connect(mapStateToProps, { success, error, fetching, setuser })
)(Products);
