import React, { useEffect, useState } from "react";
import enhancer from "./enhancer/IPFilterenhancer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NavigationActions from "redux/navigation/actions";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import Select from "react-select";
import AuthActions from "redux/auth/actions";
import { ModalHeader, ModalBody, Button } from "reactstrap";
import { getCategories } from "services/categoryServices";
import { getTrackingProducts } from "services/productServices";

const { success, error, fetching } = NavigationActions;
const { setuser } = AuthActions;

const IPFilterModal = props => {
  //VARIABLES
  const {
    token,
    // success,
    // fetching,
    // isFetching,
    error,
    // isEdit,
    onClose,
    values,
    // handleChange,
    handleSubmit,
    setValues,
    isValid,
    setFieldValue,
    // handleBlur,
    errors,
    touched,
    submitCount,
    // toggleRefresh,
    // editData,
    setEditData
  } = props;

  const [categoryOptions, setCategoryOption] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  // const [startDate, setStartDate] = useState();
  // const [endDate, setEndDate] = useState();

  const get_Categories = async () => {
    await getCategories(token).then(data => {
      if (data.success) {
        setCategoryOption(
          data.data
            .map(val => ({ value: val.id, label: val.name }))
            .filter(val => val.value > 2)
        );
      } else {
        error(data.message);
      }
    });
  };
  const get_Products = async () => {
    await getTrackingProducts(token).then(data => {
      if (data.success) {
        setProductOptions(
          data.data.map(val => ({ value: val.id, label: val.product_name }))
        );
      } else {
        error(data.message);
      }
    });
  };

  const onStartDateChange = date => {
    // setStartDate(date);
    // setEndDate("");
    setFieldValue("endDate", "");
    setFieldValue("startDate", date);
  };

  const onEndDateChange = date => {
    // setEndDate(date);
    // setFieldValue("")
    setFieldValue("endDate", date);
  };

  const Error = props => {
    const field1 = props.field;

    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  // const handleFilterSubmit = async (e) => {
  //   e.preventDefault();
  //   handleSubmit();

  //   var FiterData = {
  //     product_id: values.product_id,
  //     category_id: values.category_id,
  //   };
  //   if (isValid) {
  //     fetching();

  // isEdit
  // ? updateColor(token, colorData).then((data) => {
  //     if (data.success) {
  //       success(data.message);
  //       onClose();
  //       toggleRefresh(true);
  //     } else {
  //       error(data.message);
  //     }
  //   })
  // : addColor(token, colorData).then((data) => {
  //     if (data.success) {
  //       success(data.message);
  //       toggleRefresh(true);
  //       onClose();
  //     } else {
  //       error(data.message);
  //     }
  //   });
  //   }
  // };

  //USEEFFECTS

  useEffect(() => {
    get_Categories();
    get_Products();
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   setValues({ ...values, startDate: startDate, endDate: endDate });
  //   // eslint-disable-next-line
  // }, [startDate, endDate]);
  // console.log("errors", errors, values, "editData", editData);

  return (
    <>
      <ModalHeader toggle={() => onClose()}>Tracking Filter</ModalHeader>
      <ModalBody>
        <div className="form-group">
          <label>Select Product </label>
          <Select
            id="product_id"
            value={
              productOptions.find(x => x.value === values?.product_id)
                ? productOptions.find(x => x.value === values?.product_id)
                : null
            }
            placeholder="Select Product Name"
            onChange={e => {
              setFieldValue("product_id", e.value);
            }}
            options={productOptions}
            // values.parent_id === 0
            //   ? categoryOptions.filter(x => x.value !== values.id)
            //   : categoryOptions
          />
          {/* <Error field="parentCategory" /> */}
        </div>
        <div className="form-group">
          <label>Select Category</label>
          <Select
            id="category_id"
            value={
              categoryOptions.find(x => x.value === values?.category_id)
                ? categoryOptions.find(x => x.value === values?.category_id)
                : null
            }
            placeholder="Select Product Name"
            onChange={e => {
              setFieldValue("category_id", e.value);
            }}
            options={categoryOptions}
            // values.parent_id === 0
            //   ? categoryOptions.filter(x => x.value !== values.id)
            //   : categoryOptions
          />
          {/* <Error field="parentCategory" /> */}
        </div>
        <div className="d-flex justify-content-between mb-10">
          <div className="form-group">
            <div>
              <span>Start Date</span>
            </div>
            <DatePicker
              className="form_datepicker"
              selectsStart
              startDate={values.startDate}
              endDate={values.endDate}
              // maxDate={endDate}
              onChange={date => onStartDateChange(date)}
              selected={values.startDate}
              dateFormat="dd-MM-yy"
            />
            <div>
              <Error field="startDate" />
            </div>
          </div>

          <div className="">
            <div>
              <span>End Date</span>
            </div>
            <DatePicker
              className="form_datepicker"
              onChange={date => onEndDateChange(date)}
              selected={values.endDate}
              selectsEnd
              startDate={values.startDate}
              endDate={values.endDate}
              minDate={values.startDate}
              dateFormat="dd-MM-yy"
            />
            <div>
              <Error field="endDate" />
            </div>
          </div>
        </div>

        <div className="text-center">
          <Button
            className="btn c-primary mr-1 add_product_design"
            onClick={() => {
              handleSubmit();
              if (isValid) {
                setEditData(values);
                onClose();
              }
            }}
            // onClick={(e) => handleFilterSubmit(e)}
            type="button"
            // disabled={isFetching}
          >
            Filter
          </Button>
          <Button
            className="ml-1"
            onClick={() => {
              setValues({
                product_id: "",
                category_id: "",
                startDate: "",
                endDate: ""
              });
            }}
          >
            Reset
          </Button>
        </div>
      </ModalBody>
    </>
  );
};
const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken,
    user: state.auth.user,
    isFetching: state.navigation.isFetching
  };
};

export default compose(
  withRouter,
  enhancer,
  connect(mapStateToProps, { success, error, fetching, setuser })
)(IPFilterModal);
