import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    news: Yup.string().required("Please Enter News"),
    metaDiscription: Yup.string().required("Please Enter Meta Discription"),
    news_image: Yup.string().required("Please select News Feed Image"),
    imgAlt: Yup.string()
      .nullable()
      .required("Please enter Image Alt"),
    news_description: Yup.string().required("Please Enter News Description"),
    imageTitle: Yup.string().required("Please enter image title")
  }),
  mapPropsToValues: props => ({
    news: "",
    news_description: "",
    imageTitle: "",
    news_image: "",
    metaDiscription: "",
    imgAlt: ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
