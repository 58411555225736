import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    Artwork: Yup.array().of(
      Yup.object().shape({
        ArtworkName: Yup.string().required("Please Enter Artwork Title"),
        ArtworkImg: Yup.string().required("Please Select Artwork Image"),
        file: Yup.string().required("Please Select PDF File")
      })
    ),
    description: Yup.string().required("Please Enter Description")
  }),
  validateOnMount: true,
  mapPropsToValues: props => ({
    file: "",
    Artwork: [{ position: 1, ArtworkName: "", ArtworkImg: "", file: "" }],
    description: "<p></p>"
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
