import { api, handleResponse, handleError } from "./apiServices";

export const getIPAdd = data =>
  api()
    .post("/IPAdd/list", data)
    .then(handleResponse)
    .catch(handleError);

export const getAnalyticsFridgeMagnets = (token, data) =>
  api(token)
    .post("/visitorAnalytics/FM_list", data)
    .then(handleResponse)
    .catch(handleError);

export const getAnalyticsPrintingProducts = (token, data) =>
  api(token)
    .post("/visitorAnalytics/PM_list", data)
    .then(handleResponse)
    .catch(handleError);

export const addIPAdd = data =>
  api()
    .post("/IPAdd/add", data)
    .then(handleResponse)
    .catch(handleError);

export const updateIPAdd = data =>
  api()
    .put("/IPAdd/update", data)
    .then(handleResponse)
    .catch(handleError);

export const deleteIPAdd = data =>
  api()
    .delete("/IPAdd/delete", { data: data })
    .then(handleResponse)
    .catch(handleError);
