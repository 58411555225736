import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    product_name: Yup.string()
      .required("Please Enter Product Name")
      .matches(/^[A-Za-z0-9 ()-?]+$/, "Please enter valid Product Name"),

    parent_category_id: Yup.string().required("Please Select Parent Category"),
    category_id: Yup.string().required("Please Select Category"),
    product_status: Yup.string().required("Please Select Product Status"),
    category_name: Yup.string().when("category_id", {
      is: value => (parseInt(value) === 0 ? true : false),
      then: Yup.string().matches(
        /^[a-zA-Z0-9][a-zA-Z0-9- ]*[a-zA-Z0-9]$/,
        "Please Enter Valid Category Name"
      ),
      otherwise: Yup.string()
    }),

    brands: Yup.array().of(
      Yup.object().shape({
        brand_id: Yup.string(),
        brandimg: Yup.string().when("brand_id", {
          is: value => value,
          then: Yup.string().required("Please Select Image"),
          otherwise: Yup.string()
          // brandimg: Yup.string().required("Please Select Image")
        })
      })
    ),
    papers: Yup.array().when("category_id", {
      is: value => (parseInt(value) === 22 ? true : false),
      then: Yup.array().of(
        Yup.object().shape({
          paper_type_id: Yup.string().required("Please Select Choice of Paper"),
          Product_SKU: Yup.string().required("Please Enter SKU")
        })
      ),
      otherwise: Yup.array()
    }),
    colors: Yup.array().when("category_id", {
      is: value => (parseInt(value) === 21 ? true : false),
      then: Yup.array().of(
        Yup.object().shape({
          color_id: Yup.string().required("Please Select Color"),
          Product_SKU: Yup.string().required("Please Enter SKU")
        })
      ),
      otherwise: Yup.array()
    }),

    color_name: Yup.string(),
    product_image: Yup.string().required("Please select Product Image"),
    product_quantity: Yup.string()
      .required("Please Enter Product Quantity")
      .matches(/^[0-9,]*[0-9]$/, "Please enter valid Quantity")
    // SKU:Yup.string().required("Please enter SKU"),
  }),
  validateOnMount: true,
  mapPropsToValues: props => ({
    product_name: "",
    parent_category_id: "",
    size_id: 0,
    category_id: "",
    color_id: 0,
    paper_type_id: 0,
    marker_id: 0,
    product_image: "",
    show_on_home_page: 0,
    brands: [{ position: 1, brand_id: "", brandimg: "", show_on_homepage: 0 }],
    papers: [{ position: 1, paper_type_id: "", Product_SKU: "" }],
    colors: [{ position: 1, color_id: "", Product_SKU: "" }],
    deleted_brands: [],
    product_quantity: "",
    product_status: "",
    SKU: "",
    color_name: "",
    category_name: "",
    paper_type: "",
    size_val: "",
    marker_val: "",
    deleted_papers: [],
    deleted_colors: []
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
