import React, { useEffect, useRef, useState } from "react";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import Embed from "@editorjs/embed";
import Table from "@editorjs/table";
import List from "@editorjs/list";
import Code from "@editorjs/code";
import LinkTool from "@editorjs/link";
import Image from "@editorjs/image";
import Quote from "@editorjs/quote";
import Marker from "@editorjs/marker";
import axios from "axios";

const DEFAULT_INITIAL_DATA = {
  time: new Date().getTime()
  // blocks: [
  //   {
  //     type: "header",
  //     data: {
  //       text: "Description",
  //       level: 1,
  //     },
  //   },
  // ],
};

const EditorComponent = ({ changeEditorData, editorDefaultData, id }) => {
  const ejInstance = useRef(null); // Initialize with null
  console.log(editorDefaultData, "editorDefaultdata");
  console.log("id", id, editorDefaultData);

  const [editorContent, setEditorContent] = useState(DEFAULT_INITIAL_DATA);
  // Initialize with default data

  const initEditor = () => {
    const editor = new EditorJS({
      holder: "editorjs",
      onReady: () => {
        ejInstance.current = editor;
      },
      autofocus: true,
      data: id ? editorDefaultData : DEFAULT_INITIAL_DATA,

      onChange: async () => {
        let content = await editor.saver.save();
        setEditorContent(content);
        console.log("content", content);
        changeEditorData(content);
      },
      tools: {
        header: Header,
        embed: Embed,
        table: Table,
        marker: Marker,
        list: List,
        code: Code,
        linkTool: {
          class: LinkTool,
          config: {
            endpoint: `${process.env.REACT_APP_BACKEND_URI}/editor/insert-link`
          }
        },
        image: {
          class: Image,
          config: {
            uploader: {
              uploadByFile: async file => {
                const formData = new FormData();
                formData.append("product_Images", file);

                let data = await axios.post(
                  `${process.env.REACT_APP_BACKEND_URI}/editor`,
                  formData
                );
                if (data.data.success) {
                  return {
                    success: 1,
                    file: {
                      url: `${process.env.REACT_APP_BACKEND_URI}/${data.data.path}`
                    }
                  };
                } else {
                  return {
                    success: 0,
                    file: {
                      path: data.path,
                      message: "failed to upload image"
                    }
                  };
                }
              }
            }
          }
        },
        quote: Quote
      }
    });
  };

  useEffect(() => {
    if (!editorDefaultData && !id) {
      if (ejInstance.current === null) {
        initEditor();
      }
    }
  }, []);

  useEffect(() => {
    if (editorDefaultData) {
      if (ejInstance.current === null) {
        initEditor();
      }
    }
  }, [editorDefaultData]);

  return (
    <>
      <div id="editorjs"></div>
      {/* Uncomment and use this button when needed */}
      {/* <button
        onClick={async () => {
          let content = await axios.post("http://localhost:5002/create", {
            content: editorContent,
          });
          if (content.success) {
            console.log("content", content.data);
          }
        }}
      >
        Create Content
      </button> */}
    </>
  );
};

export default EditorComponent;
