import React, { useEffect, useState } from "react";
import enhancer from "./enhancer/faqenhancer";
import NavigationActions from "redux/navigation/actions";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import AuthActions from "redux/auth/actions";
import { ModalHeader, ModalBody, Button } from "reactstrap";
import CreatableSelect from "react-select/creatable";
import { addFAQ, updateFAQ } from "services/faqServices";
import { getFAQTypes } from "services/faqServices";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const { success, error, fetching } = NavigationActions;
const { setuser } = AuthActions;

const FAQsAddModal = props => {
  //VARIABLES
  const {
    token,
    success,
    fetching,
    isFetching,
    setFieldValue,
    error,
    isEdit,
    onClose,
    values,
    handleChange,
    handleSubmit,
    setValues,
    isValid,
    handleBlur,
    errors,
    touched,
    submitCount,
    toggleRefresh,
    editData
  } = props;

  //USESTATE

  const [options, setOptions] = useState([]);

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const handleFAQSubmit = async e => {
    e.preventDefault();
    handleSubmit();
    var faqData = {
      id: editData.id,
      faq_id: values.faq_id,
      faq_name: values.faq_name,
      question: values.question,
      answer: values.answer
    };

    if (isValid) {
      fetching();
      isEdit
        ? updateFAQ(token, faqData).then(data => {
            if (data.success) {
              success(data.message);
              onClose();
              toggleRefresh(true);
            } else {
              error(data.message);
            }
          })
        : addFAQ(token, faqData).then(data => {
            if (data.success) {
              success(data.message);
              toggleRefresh(true);
              onClose();
            } else {
              error(data.message);
            }
          });
    }
  };

  const getFAQData = async () => {
    await getFAQTypes(token).then(data => {
      if (data.success) {
        setOptions(
          data.data.map(val => ({
            value: val.id,
            label: val.faq_name
          }))
        );
      } else {
        error(data.message);
      }
    });
  };

  //USEEFFECTS

  useEffect(() => {
    getFAQData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    isEdit &&
      setValues({
        ...editData
      });

    // eslint-disable-next-line
  }, [editData]);

  return (
    <>
      <ModalHeader toggle={() => onClose()}>
        {`${isEdit ? "Edit" : "Add"} FAQ`}
      </ModalHeader>
      <ModalBody>
        <div className="form-group">
          <label>
            FAQ Type <span className="error-msg">*</span>
          </label>
          <CreatableSelect
            isClearable
            value={
              values.faq_name && values.faq_name !== null && values.faq_id === 0
                ? {
                    value: values.faq_name,
                    label: values.faq_name
                  }
                : options.find(x => x.value === values.faq_id)
                ? options.find(x => x.value === values.faq_id)
                : null
            }
            onCreateOption={val => {
              setFieldValue("faq_id", 0);
              setFieldValue("faq_name", val);
            }}
            onChange={val => {
              if (val) {
                setFieldValue("faq_name", val?.label);
                setFieldValue("faq_id", val?.value);
              } else if (val === null) {
                setFieldValue("faq_name");
                setFieldValue("faq_id", "");
              }
            }}
            options={options}
          />
          <Error field="faq_id" />
          <Error field="faq_name" />
        </div>

        <div className="form-group">
          <label>
            Question <span className="error-msg">*</span>
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            placeholder="Enter The Question"
            id="question"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.question}
          />
          <Error field="question" />
        </div>

        <div className="form-group">
          <label>
            Answer <span className="error-msg">*</span>
          </label>
          {/* <textarea
            className="form-control react-form-input"
            placeholder="Enter The Answer"
            id="answer"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.answer}
          /> */}
          <ReactQuill
            onChange={(content, delta, source, editor) => {
              if (editor.getLength() <= 1) {
                setFieldValue("answer", "");
              } else {
                setFieldValue("answer", content);
              }
            }}
            value={values.answer}
            theme={"snow"}
            placeholder={"Enter the Answer Here... "}
            modules={FAQsAddModal.modules}
            formats={FAQsAddModal.formats}
            bounds={".app"}
            id="answer"
            name="answer"
          />
          <Error field="answer" />
        </div>

        <Button
          className="btn c-primary btn-block add_product_design"
          onClick={e => handleFAQSubmit(e)}
          type="button"
          disabled={isFetching}
        >
          {isEdit ? "Update" : "Save"}
        </Button>
      </ModalBody>
    </>
  );
};
const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken,
    user: state.auth.user,
    isFetching: state.navigation.isFetching
  };
};
FAQsAddModal.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ]
  ],
  clipboard: {
    matchVisual: false
  }
};
FAQsAddModal.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent"
];

export default compose(
  withRouter,
  enhancer,
  connect(mapStateToProps, { success, error, fetching, setuser })
)(FAQsAddModal);
