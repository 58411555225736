import React, { useEffect, useState } from "react";
import enhancer from "./enhancer/artworkenhancer";
import NavigationActions from "redux/navigation/actions";
import { useParams, withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import AuthActions from "redux/auth/actions";
import { Button } from "reactstrap";
import { getartwork, updateartwork } from "services/artworkServices";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const { success, error, fetching } = NavigationActions;
const { setuser } = AuthActions;

const ArtWorks = props => {
  //VARIABLES
  const {
    token,
    success,
    fetching,
    isFetching,
    error,
    setFieldValue,
    values,
    handleSubmit,
    setValues,
    isValid,
    handleBlur,
    // handleChange,
    errors,
    touched,
    submitCount
  } = props;

  const { id } = useParams();
  // const [productImagesArr, setProductImagesArr] = useState([]);
  const [ArtworkimagArr, setArtworkImgArr] = useState([]);
  const [ArtworkImage, setArtworkImage] = useState([]);
  const [ArtworkfileArr, setArtworkfileArr] = useState([]);

  //USESTATE

  const handleArtworkSubmit = async e => {
    e.preventDefault();
    handleSubmit();

    var formData = new FormData();
    for (const val in values) {
      // if (val === "deleted_Artwork") {
      //   formData.append(val, JSON.stringify(values[val]));
      // }
      // else
      if (val === "Artwork") {
        ArtworkimagArr.map(x => formData.append("artworkImage", x));
        if (typeof values[val] === "object") {
          formData.append(val, JSON.stringify(values[val]));
        } else if (val === "ArtworkName") {
          formData.append(val, values[val]);
        }
      } else if (val === "file") {
        ArtworkfileArr.map(x => formData.append("artworkfile", x));
        if (typeof values[val] === "object") {
          formData.append(val, JSON.stringify(values[val]));
        }
      } else if (val === "description") {
        formData.append(val, values[val]);
      }
    }

    if (isValid) {
      fetching();

      await updateartwork(token, formData).then(data => {
        if (data.success) {
          success(data.message);
        } else {
          error(data.message);
        }
      });
    }
  };

  const onArtworkImageChange = (e, k) => {
    const [file] = e.target.files;
    if (file) {
      ArtworkImage[k] = URL.createObjectURL(file);
      setArtworkImage(ArtworkImage);
    } else {
      ArtworkImage[k] = "";
      setArtworkImage(ArtworkImage);
    }
  };

  const ArtworksError = props => {
    const field1 = props.field;
    const index = props.index;
    if (
      (errors &&
        errors.hasOwnProperty("Artwork") &&
        errors?.Artwork[index] &&
        errors?.Artwork[index][field1] &&
        touched &&
        touched.hasOwnProperty("Artwork") &&
        touched?.Artwork[index] &&
        touched?.Artwork[index][field1]) ||
      submitCount > 0
    ) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors &&
            errors.hasOwnProperty("Artwork") &&
            errors?.Artwork[index] &&
            errors?.Artwork[index][field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };
  const getartworks = async () => {
    await getartwork(token).then(data => {
      if (data.success) {
        setValues({
          ...values,
          ...data.data
        });

        setArtworkImgArr(data.data?.Artwork.map(val => val.ArtworkImg));
        setArtworkfileArr(data.data?.Artwork.map(val => val.file));
        success();
      } else {
        error(data.message);
      }
    });
  };
  useEffect(() => {
    getartworks();
    // eslint-disable-next-line
  }, []);
  //USEEFFECTS

  return (
    <>
      <div className="">
        <div className="mx-3">
          <div className="row mt-10 mb-10">
            <div className="col-12 mb-2">
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "bold"
                }}
              >
                Artworks
              </span>
            </div>
          </div>
        </div>

        {/* ADD PRODUCT */}
        <div className="card m-2 p-2">
          <div className="card-body">
            <div className="row">
              <div className="col-10">
                <div className="form-group">
                  <label style={{ fontWeight: "bold" }}>
                    File Upload
                    {/* <span className="error-msg">*</span> */}
                  </label>
                </div>
              </div>
              <div className="col-2 text-center">
                <button
                  className="btn btn-white border-0"
                  onClick={() => {
                    values.Artwork.push({
                      position: values.Artwork.length + 1,
                      ArtWorksimg: "",
                      file: ""
                    });
                    setValues(values);
                  }}
                >
                  {/* <i class="fas fa-plus-circle" style={{ color: "#000000" }}></i> */}

                  <AddCircleOutlineIcon />
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-4">
                    <div>
                      <label>
                        Artwork Title<span className="error-msg"> *</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-3">
                    <div>
                      <label>
                        Artwork Image <span className="error-msg"> *</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-3">
                    <div>
                      <label>
                        Artwork file <span className="error-msg"> *</span>
                      </label>
                    </div>
                  </div>
                </div>
                {values.Artwork !== undefined &&
                  values.Artwork.map((s, k) => (
                    <div className="row" key={k}>
                      <div className="col-4">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control form"
                            placeholder="Enter The Artwork Name"
                            id={`Artwork[${k}].ArtworkName`}
                            onBlur={handleBlur}
                            onChange={e =>
                              setFieldValue(
                                `Artwork[${k}].ArtworkName`,
                                e.target.value
                              )
                            }
                            value={values.Artwork[k].ArtworkName}
                          />
                        </div>
                        <ArtworksError field="ArtworkName" index={k} />
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                          <input
                            type="file"
                            className="mr-2"
                            id={`Artwork[${k}].ArtworkImg`}
                            accept="image/png, image/gif, image/jpeg"
                            onBlur={handleBlur}
                            onChange={e => {
                              if (e.target.files[0]) {
                                onArtworkImageChange(e, k);
                                ArtworkimagArr[k] = e.target.files[0];
                                setArtworkImgArr(ArtworkimagArr);
                                setFieldValue(
                                  `Artwork[${k}].ArtworkImg`,
                                  e.target.files[0]?.name
                                );
                              } else {
                                setFieldValue(`Artwork[${k}].ArtworkImg`, "");
                                ArtworkimagArr[k] = "";
                                setArtworkImgArr([...ArtworkimagArr]);
                              }
                            }}
                          />
                          <>
                            {values.Artwork[k].ArtworkImg !== "" &&
                            ArtworkImage[k] ? (
                              <a
                                href={ArtworkImage[k]}
                                alt={"product_image"}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="thumbnail_img"
                              >
                                <img src={ArtworkImage[k]} alt="product-img" />
                              </a>
                            ) : (
                              <>
                                <a
                                  href={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${values.Artwork[k].ArtworkImg}`}
                                  alt={"brand_image"}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className={
                                    values.Artwork[k].ArtworkImg
                                      ? "d-block thumbnail_img"
                                      : "d-none"
                                  }
                                >
                                  <img
                                    src={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${values.Artwork[k].ArtworkImg}`}
                                    alt="product-img"
                                  />
                                </a>
                              </>
                            )}
                          </>
                        </div>
                        <ArtworksError field="ArtworkImg" index={k} />
                      </div>

                      <div className="col-3">
                        <div className="form-group">
                          <input
                            type="file"
                            className="mr-2"
                            id={`Artwork[${k}].file`}
                            accept="application/pdf"
                            onBlur={handleBlur}
                            onChange={e => {
                              if (e.target.files[0]) {
                                // onArtworkImageChange(e, k);
                                ArtworkfileArr[k] = e.target.files[0];
                                setArtworkfileArr(ArtworkfileArr);
                                setFieldValue(
                                  `Artwork[${k}].file`,
                                  e.target.files[0]?.name
                                );
                              } else {
                                setFieldValue(`Artwork[${k}].file`, "");
                                ArtworkfileArr[k] = "";
                                setArtworkfileArr([...ArtworkfileArr]);
                              }
                            }}
                          />
                          <span>{values.Artwork[k].file}</span>
                        </div>
                        <ArtworksError field="file" index={k} />
                      </div>

                      <div className="col-2">
                        <div className="text-center color-black">
                          <button
                            className="btn btn-link mt-4 pt-2 btn-white border-0 react-form-input"
                            type="button"
                            disabled={values.Artwork.length <= 1}
                            onClick={() => {
                              if (id) {
                                values.deleted_Artwork.push(
                                  values.Artwork[k].id
                                );
                              }
                              values.Artwork.splice(k, 1);
                              setValues(values);
                            }}
                          >
                            {/* <i
                            class="fas fa-minus-circle"
                            style={{ color: "#000000" }}
                          ></i> */}

                            <RemoveCircleOutlineIcon />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                {/* <input
                  type="file"
                  className="form-control react-form-input"
                  placeholder="Category Name"
                  id="ArtWorksimg"
                  name="ArtWorksimg"
                  accept=".jpg,.pdf,.png,.jpeg"
                  onBlur={handleBlur}
                  onChange={e => {
                    setProductImagesArr(e.target.files);
                    setFieldValue("ArtWorksimg", e.target.files);
                  }}
                  // onChange={handleChange}
                  // value={values?.file}
                  style={{ padding: "3px" }}
                /> */}
              </div>

              <div className="row">
                <div className="col-12">
                  <label style={{ fontWeight: "bold" }}>
                    Artworks Description
                    <span className="error-msg ml-1">*</span>
                  </label>
                  <div className="">
                    <ReactQuill
                      onChange={(content, delta, source, editor) => {
                        if (editor.getLength() <= 1) {
                          setFieldValue("description", "");
                        } else {
                          setFieldValue("description", content);
                        }
                      }}
                      value={values?.description}
                      theme={"snow"}
                      placeholder={"Enter Description... "}
                      modules={ArtWorks.modules}
                      formats={ArtWorks.formats}
                      bounds={".app"}
                      id="description"
                      name="description"
                    />

                    <Error field="description" />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 text-center mt-10">
                <Button
                  className="btn c-primary px-5 add_product_design"
                  onClick={e => handleArtworkSubmit(e)}
                  type="button"
                  disabled={isFetching}
                >
                  Update
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ArtWorks.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ]
  ],
  clipboard: {
    matchVisual: false
  }
};
ArtWorks.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent"
];
const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken,
    user: state.auth.user,
    isFetching: state.navigation.isFetching
  };
};

export default compose(
  withRouter,
  enhancer,
  connect(mapStateToProps, { success, error, fetching, setuser })
)(ArtWorks);
